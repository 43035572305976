.prop-container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: scroll;
}
.prop-row-main{
    display: flex;
    flex-direction: column;
}
